import React, { useState, useEffect } from "react";
import { Image, Placeholder } from "cloudinary-react";
import config from "../../config";

//css
import * as styles from "../Webinar/webinar.module.css";

function ThankingShareSection(props) {
  const [cLink, setClink] = useState();
  const [copy, setCopy] = useState(false);
  const copyEvent = e => {
    navigator.clipboard.writeText(cLink);
    setCopy(true);
  };

  useEffect(() => {
    if(props.webinarType === "freeWebinar") {
      setClink(window.location.origin + "/reversal-webinar-free");
    } else {
      setClink(window.location.origin + "/reversal-webinar");
    }
  }, [props]);

  return (
    <div className={styles.share_container}>
      <div className={styles.shareLogoCo}>
        <Image
          secure="true"
          cloudName={config.CLOUDINARY_NAME}
          publicId="Webinar/hearthand_gnip4c.svg"
          dpr="auto"
          responsive
          width="auto"
          crop="scale"
          responsiveUseBreakpoints="true"
          fetchFormat="webp"
          quality="auto"
          className={styles.hearthand}
        >
          <Placeholder type="blur"></Placeholder>
        </Image>
        <p className={styles.shareCoTxt}> Share </p>
      </div>

      <p className={styles.shareCo}>
        {props.name}, help your loved ones beat diabetes
      </p>
      <p className={styles.descCo}>
        Invite your friends & family, dealing with diabetes, to enjoy life
        medicine-free
      </p>

      <div className={styles.share_link_container}>
        <div className={styles.share_link_name}>
          {" "}
          <a target="_blank" rel="noreferrer" href={cLink}>
            {" "}
            twinhealth.com/webinar
          </a>{" "}
        </div>
        <div
          onClick={copyEvent}
          role="presentation"
          className={styles.copy_link}
        >
          {" "}
          <p>
            <Image
              secure="true"
              cloudName={config.CLOUDINARY_NAME}
              publicId="Webinar/share_icon_omgu49.svg"
              dpr="auto"
              responsive
              width="auto"
              crop="scale"
              responsiveUseBreakpoints="true"
              fetchFormat="webp"
              quality="auto"
              className={styles.share_icon}
            >
              <Placeholder type="blur"></Placeholder>
            </Image>{" "}
            {copy ? "COPIED" : "COPY LINK"}
          </p>{" "}
        </div>
      </div>
      <div className={styles.share_link_mo_container}>
        <div className={styles.share_link_name}>
          <p>
            twinhealth.com/reversal-webinar
          </p>
        </div>
        <div
          onClick={copyEvent}
          role="presentation"
          className={styles.copy_link}
        >
          {" "}
          <p>
            <Image
              secure="true"
              cloudName={config.CLOUDINARY_NAME}
              publicId="Webinar/share_icon_omgu49.svg"
              dpr="auto"
              responsive
              width="auto"
              crop="scale"
              responsiveUseBreakpoints="true"
              fetchFormat="webp"
              quality="auto"
              className={styles.share_icon}
            >
              <Placeholder type="blur"></Placeholder>
            </Image>{" "}
            {copy ? "COPIED" : "COPY LINK"}
          </p>{" "}
        </div>
      </div>
    </div>
  );
}

export default ThankingShareSection;
