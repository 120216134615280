import React, { useState, useEffect } from "react";
import queryString from "query-string";
import axios from "axios";
import { Image, Placeholder } from "cloudinary-react";

// seo
import Seo from "../components/Seo";

// config
import config from "../config";

// components
import Thanking from "../components/Webinar/thanking";
import ThankingShareSection from "../components/Webinar/thankingShare";
// import TwinAppWebinarPaymentHeader from "../components/Webinar/TwinAppWebinarPaymentHeader";
import Button from "../components/Button/Button";

// css
import * as styles from "../components/Webinar/webinar.module.css";

function WebinarThanking(props) {
  const [payload, setPayload] = useState(null);
  const [zoomLinkData, setZoomLinkData] = useState(null);

  const pixelEvent = async (pixelClickId, webinarPrice) => {
    // console.log("pixel called");
    console.log("pixel_clickid", pixelClickId, webinarPrice);
    const options = {
      method: "get",
      url: `https://offers-afftracer.affise.com/postback?clickid=${pixelClickId}&afgoal=Webinar_Register&afprice=${webinarPrice}&afstatus=1`,
    };

    try {
      const res = await axios(options);
      console.log("pixel res", res.data);
    } catch (error) {
      console.log("pixel server error", error);
    }
  };

  useEffect(() => {
    if (window.twinApp) {
      let data = JSON.stringify({ action: "hide_app_header" });
      window.twinApp.postMessage(data);
    }

    if (props.location.search) {
      const params = queryString.parse(
        props.location.search.replaceAll("_", " ")
      );
      setPayload(params);
      if (params.pixelClickId) {
        pixelEvent(params.pixelClickId, params.webinarPrice);
      }
    }
  }, [props]);

  useEffect(() => {
    if (payload) {
      // Pixel Integration
      // if (typeof window !== "undefined") {
      //   window.afoffer_id = 33;
      //   window.afid = "webinarRegisterCta";
      //   window.afgoal = "Webinar_Payment";
      //   window.afprice = payload.webinarPrice;
      //   window.afstatus = 1;
      // }
      // End pixel integration

      // zoom url integration
      if (payload.orderId && payload.schedularId) {
        axios
          .get(
            config.DX_API_BASE_URL +
              "/api/schedular/videoLink?orderId=" +
              payload.orderId +
              "&schedularId=" +
              payload.schedularId,

            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${config.JWT_TOKEN}`,
              },
            }
          )
          .then(res => {
            setZoomLinkData(res.data);
          })
          .catch(err => {
            setZoomLinkData(null);
            console.log(err);
          });
      }
      // End zoom integration
    }
  }, [payload]);

  // Start of Pixel Integration
  // useEffect(() => {}, []);
  // End of pixel integration

  function openInNewTab(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }

  const getCallbackUrl = () => {
    return (
      window.location.origin +
      "/webinar/webinar-thanking?" +
      queryString.stringify(payload) +
      "&addToC=1"
    );
  };

  const addToCalender = () => {
    if (window !== "undefined") {
      const url = encodeURIComponent(getCallbackUrl());

      window.location =
        config.DX_API_BASE_URL +
        "/api/cal/add/" +
        payload.orderId +
        "?url=" +
        url;
    }
  };

  const handleTwinAppRedirection = () => {
    if (window.twinApp) {
      console.log("==>> present in window twinApp");
      let data = JSON.stringify({ action: "dx_home" });
      window.twinApp.postMessage(data);
    } else if (window.webkit) {
      console.log("==>> present in window");
      window.webkit.messageHandlers.twinApp.postMessage({
        action: "dx_home",
      });
    } else {
      console.log("Back from webinar page");
    }
  };

  return (
    <div>
      <Seo
        title="Diabetes Webinar | Diabetes Reversal Program | Twin"
        description="Diabetes Webinar. 1000's have reversed their diabetes using Twin's proven AI based precision treatment."
      />

      {/* <!---Component Starts-----> */}
      <div className="container">
        {/* {payload && payload.twinApp && payload.twinApp === "yes" && (
          <TwinAppWebinarPaymentHeader />
        )} */}
        <div className={`row ${styles.ThankC}`}>
          <Thanking payload={payload} />
          <div className={styles.myConCo}>
            <div className={`${styles.timCon} ps-0 pe-0`}>
              <span className={styles.timConTxt1}>Webinar topic</span>
              <span className={styles.timConTxt2}>
                {payload && payload.webinarTopic}
              </span>
            </div>
            <div className={`${styles.timCon} ps-0 pe-0`}>
              <span className={styles.timConTxt1}>
                Webinar date, time & language
              </span>
              <span className={styles.timConTxt2}>
                {payload && payload.webinarTiming}
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center mt-4">
            {payload && payload.twinApp && payload.twinApp === "yes" && (
              <Button
                type="button"
                text="Go to home"
                onClick={handleTwinAppRedirection}
                btnClass={styles.twinAppBtn}
              />
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            {zoomLinkData && zoomLinkData.results.webinarLink ? (
              <div className={styles.webinarLink}>
                <span className={styles.webinarJointxt}>
                  Webinar joining link
                </span>

                <div>
                  <span
                    role="presentation"
                    onClick={() =>
                      openInNewTab(zoomLinkData.results.webinarLink)
                    }
                    className={styles.webinarZoomLink}
                  >
                    {zoomLinkData && zoomLinkData.results.webinarLink}
                  </span>
                </div>

                {!payload.addToC && payload.addToC !== "1" ? (
                  <div className={styles.addToCon}>
                    <div
                      role="presentation"
                      onClick={addToCalender}
                      className={styles.CalCo}
                    >
                      <Image
                        secure="true"
                        cloudName={config.CLOUDINARY_NAME}
                        publicId="Webinar/image_412_hhfm1u.svg"
                        dpr="auto"
                        responsive
                        width="auto"
                        crop="scale"
                        responsiveUseBreakpoints="true"
                        fetchFormat="webp"
                        quality="auto"
                        className={styles.calImg}
                      >
                        <Placeholder type="blur"></Placeholder>
                      </Image>
                      <span className={styles.AddCanTxt}>
                        Add to Google Calender
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className={styles.addToCon}>
                    <div className={styles.CalCo1}>
                      <Image
                        secure="true"
                        cloudName={config.CLOUDINARY_NAME}
                        publicId="Webinar/Vector_6_gktnq3.svg"
                        dpr="auto"
                        responsive
                        width="auto"
                        crop="scale"
                        responsiveUseBreakpoints="true"
                        fetchFormat="webp"
                        quality="auto"
                        className={styles.calImg1}
                      >
                        <Placeholder type="blur"></Placeholder>
                      </Image>
                      <span className={styles.AddCanTxt1}>
                        Added to your google calender
                      </span>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className={`${styles.otherCon}`}>
                <span className={`${styles.otherContTxt1}`}>
                  Important information
                </span>
                <span className={`${styles.otherConTxt2}`}>
                  The joining details of the webinar are sent to your registered
                  email
                </span>
              </div>
            )}
            <ThankingShareSection
              name={payload && payload.name}
              webinarType={payload && payload.webinarType}
            />
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    </div>
  );
}

export default WebinarThanking;
