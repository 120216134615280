import React, { useState, useEffect } from "react";
//config
import config from "../../config";
import Confetti from "react-confetti";
import { Image, Placeholder } from "cloudinary-react";

//css
import * as styles from "../Webinar/webinar.module.css";

function Thanking(props) {
  const [runConf, setRunConf] = useState(true);

  useEffect(() => {
    setTimeout(() => setRunConf(false), 7000);
  }, [runConf]);
  return (
    <div className={styles.thanks_conatainer}>
      <div>
        <Confetti
          className={styles.confiCo}
          height={400}
          width={900}
          numberOfPieces={100}
          // tweenDuration={10}
          recycle={runConf}
        />
      </div>
      <div className={styles.thanks_section1}>
        <div className={styles.thankCon}>
          <Image
            secure="true"
            cloudName={config.CLOUDINARY_NAME}
            publicId="app-images/green_check_icon_2x_qml07i.png"
            dpr="auto"
            responsive
            width="auto"
            crop="scale"
            responsiveUseBreakpoints="true"
            fetchFormat="webp"
            quality="auto"
            className={styles.greentick}
          >
            <Placeholder type="blur"></Placeholder>
          </Image>

          <div className={styles.thanksmsg}>
            <p className={styles.sucCo}>
              Successfully registered for the webinar
            </p>
            <p className={styles.timCo1}>
              {props.payload && props.payload.webinarTopic}
            </p>
            <p className={styles.timCo}>
              {props.payload && props.payload.webinarTiming}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Thanking;
